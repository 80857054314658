<template>
    <div class="container">
        <div>
            <el-card class="box-card" shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>查询</span>
                    </div>
                </template>
                <div class="card-content">
                    <el-form :inline="true" label-width="100px" label-position="right">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="起止时间：" style="width:292px">
                                    <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm:ss"
                                        value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入创建时间" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" style="width:292px">
                                    <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm:ss"
                                        value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入截至时间" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                            </el-col>
                            <el-col :span="8">
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
        </div>
    </div>
    <div class="content">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>补贴金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>0</span>
                        </div>
                        <div>
                            <span>数量</span>
                        </div>
                    </div>
                </el-card>
            </el-col>

            <el-col :span="12">
                <el-card shadow="never" class="card-border">
                    <template #header>
                        <div class="card-header">
                            <span>支出金额</span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div>
                            <span>0</span>
                        </div>
                        <div>
                            <span>数量</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
    <div class="detail">
        <el-card shadow="never" class="card-border" v-loading="loading">
            <template #header>
                <div class="card-header">
                    <span>订单明细</span>
                </div>
            </template>
            <div>
                <el-table :data="tableData" stripe border style="width: 100%" fit>
                    <el-table-column prop="out_trade_no" label="订单号" >
                    </el-table-column>
                    <el-table-column prop="trade_no" label="商户">
                    </el-table-column>
                    <el-table-column prop="shanghu" label="类型">
                    </el-table-column>
                    <el-table-column prop="fendian" label="金额">
                    </el-table-column>
                    <el-table-column prop="created_at_fat" label="变更原因">
                    </el-table-column>
                    <el-table-column prop="money" label="变更时间">
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination :page-size="pagination.perPage" :pager-count="7" layout="prev, pager, next"
                    :total="pagination.total" @current-change="handleSizeChange">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from "vue";
import { GetTodayStartTime, GetTodayEndTime } from "../../tools/dateTools"

const { proxy } = getCurrentInstance()
const tableData = ref([])
const loading = ref(true)

const pagination = ref({
    total: 0,
    perPage: 0,
})
onMounted(() => { getOrderList(serchDate.value) })

const getOrderList = async (data) => {
    loading.value = true
    await proxy.$api.order.getOrderList(data).then(res => {
        if (res.status == 200 && res.success) {
            tableData.value = res.data
            pagination.value = res.pagination
            console.log(tableData);
        }
        loading.value = false
    })
}

//初始页
const serchDate = ref({
    created_start: GetTodayStartTime(),
    created_end: GetTodayEndTime(),
    page: 1
})

//获取下一页
const handleSizeChange = (val) => {
    serchDate.value.page = val
    getOrderList(serchDate.value)
}
</script>


<style lang="less" scoped>
.container {
    width: 100%;
    box-sizing: border-box;

    .box-card {
        border-top: 5px solid #e7e7e7;
    }

    .card-header {
        span {
            font-size: 15px;
            font-weight: bold;
        }
    }
}
.content {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;
        .card-header{
                   span {
            font-size: 15px;
            font-weight: bold;
        } 
        }

    }

    .card-content {
        box-sizing: border-box;

        div:nth-child(1) {
            font-size: 20px;
            color: #504f4f;
            padding-left: 6px;
            font-weight: bold;
        }

        div:nth-child(2) {
            font-size: 14px;
            color: #504f4f;
            padding-top: 6px;
            padding-left: 6px;
            font-weight: bold;
        }
    }
}

.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }
        .pagination{
            display: flex;
            justify-content: flex-end;
        }
    }

}
</style>